.userInfoContainer {
  display: grid;
  grid-template-columns: [img] 30% [data] 70%;
  width: 100%;
}

.userInfoContainer > * {
  margin: 0.2rem;
}

.usrAvatar {
  grid-column-start: img;
  grid-column-end: img;
  width: 100%;
  border-radius: 33% 67% 21% 79% / 56% 51% 49% 44%;
  filter: drop-shadow(3px 3px 4px black);
  transition: all 1s ease;
  margin-top: 2rem;
  align-self: center;
  justify-self: center;
}

.usrAvatar:hover {
  border-radius: 10px;
  transition: all 1s ease;
}

.usrData {
  grid-column-start: data;
  grid-column-end: data;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;
}

@media (max-width: 800px) {
  .userInfoContainer {
    display: flex;
    flex-direction: column;
  }

  .usrAvatar {
    max-width: 75%;
  }
}
