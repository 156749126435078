.hobbiesContainer {
  align-self: flex-start;
  text-align: justify;
  width: 100%;
  padding-bottom: 1rem;
}

.hobbiesContainer .hobbieState {
  text-align: center;
}

.hobbies {
  list-style-type: none;
  box-sizing: border-box;
  width: 100%;
  margin-left: -30px;
  margin-right: 5px;
}

.hobbies li::before {
  content: '\002B';
}

.hobbieElement {
  width: fit-content;
  padding: 0.5rem 0;
}

.hobbieElement:hover {
  cursor: pointer;
}

.hobbieElement:focus-within li::before {
  content: '\2212';
}

.hobbieElement:focus-within .hobbieDescription {
  display: block;
}

.hobbieDescription {
  display: none;
}