body {
  background-color: #2c2f33;
  color: #ccd2eb;
}

h1 {
  text-align: center;
  text-shadow: 0 0 20px	#99aab5e0;
}

.userCardContainer {
  color: #23272a;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.userCard {
  position: relative;
  margin: 2rem;
  padding: 1rem;
  max-width: 40%;
  height: fit-content;
  background-color:	#ccd2eb;
  box-shadow: inset 0px 1.5rem	#7289da, 1px 1px 3px black;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
}

.marker {
  position: absolute;
  top: -1.4rem;
  right: 0.2rem;
  color: #23272a81;
}

@media (max-width: 500px) {
  .userCard {
    min-width: fit-content;
  }
}